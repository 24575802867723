<template>
  <div>
    <div
      class="modal right fade"
      :id="idModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog report-modal">
        <div class="modal-content">
          <div class="card-modal">
            <div class="modal-header">
              <h5 class="modal-title">Parameter Laporan</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body mt-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="modal-sub-title">Periode</div>
                <button
                  class="btn btn-save"
                  type="button"
                  @click="formModal.isMonth = !formModal.isMonth"
                  v-if="isYearEnable"
                  style="
                    background: #68caf8;
                    width: unset;
                    padding: 7px;
                    font-size: 13px;
                  "
                >
                  {{
                    formModal.isMonth
                      ? "Filter berdasarkan tahun"
                      : "Filter berdasarkan bulan"
                  }}
                </button>
              </div>
              <hr />

              <div class="form-group d-flex align-items-center">
                <label class="form-label">Dari Periode </label>
                <div class="row w-100">
                  <div class="col-12">
                    <Datepicker
                      v-if="formModal.isMonth"
                      :autoApply="true"
                      v-model="formModal.date_start"
                      selectText="Pilih"
                      cancelText="Batal"
                      class="w-100"
                      :enableTimePicker="false"
                      :maxDate="new Date()"
                      :format="format"
                      monthPicker
                      placeholder="Periode Mulai"
                      @update:modelValue="dateReport($event, 'date_start')"
                    ></Datepicker>
                    <Select2
                      v-else
                      class=""
                      style="width: 100%"
                      v-model="formModal.date_start"
                      :options="optionYearStart"
                      placeholder="Pilih Tahun"
                      @change="mySelectEvent('date_start', $event)"
                      @select="mySelectEvent('date_start', $event)"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group d-flex align-items-center">
                <label class="form-label">S/d Periode </label>
                <div class="row w-100">
                  <div class="col-12">
                    <Datepicker
                      v-if="formModal.isMonth"
                      :autoApply="true"
                      v-model="formModal.date_end"
                      selectText="Pilih"
                      cancelText="Batal"
                      class="w-100"
                      :enableTimePicker="false"
                      :maxDate="new Date()"
                      :format="format"
                      monthPicker
                      placeholder="Periode Selesai"
                      @update:modelValue="dateReport($event, 'date_end')"
                    ></Datepicker>
                    <Select2
                      v-else
                      class=""
                      style="width: 100%"
                      v-model="formModal.date_end"
                      :options="optionYearEnd"
                      placeholder="Pilih Tahun"
                      @change="mySelectEvent('date_end', $event)"
                      @select="mySelectEvent('date_end', $event)"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-sub-title">Parameter Tambahan</div>
              <hr />

              <div
                class="form-group d-flex align-items-center mb-3"
                v-if="isLedger"
              >
                <label class="form-label">Akun Perkiraan</label>
                <Select2
                  style="width: 100%"
                  v-model="formModal.id_akun"
                  :options="optionAkun"
                  placeholder="Pilih Akun Perkiraan"
                  :settings="{
                    allowClear: true,
                    templateResult: formatState,
                    multiple: true,
                  }"
                />
              </div>

              <div class="form-group d-flex align-items-center">
                <label class="form-label">Departemen</label>
                <Select2
                  style="width: 100%"
                  v-model="formModal.id_divisi"
                  :options="optionDepartement"
                  placeholder="Pilih Departement"
                  @change="myChangeEvent($event)"
                  @select="mySelectEvent($event)"
                  :settings="{
                    allowClear: true,
                  }"
                />
              </div>

              <div
                class="form-group"
                v-if="isMergeValue && !formModal.id_divisi"
              >
                <div class="clearfix">
                  <label for="form-label flaot-left"
                    >Gabungkan Nilai Akun Perkiraan Departemen</label
                  >
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="formModal.isAll"
                        :checked="formModal.isAll"
                        :value="true"
                        class="custom-control-input"
                        :id="`customrangeall`"
                        @change="changeCheckbox('all')"
                      />
                      <label
                        class="custom-control-label"
                        :for="`customrangeall`"
                        >Pilih Semua</label
                      >
                    </div>
                  </div>
                  <div
                    class="col-12"
                    v-for="(value, key) in optionDepartement"
                    :key="key"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="formModal.merge"
                        :checked="
                          formModal.merge.includes(value.id) ||
                          formModal.merge.includes(value.id.toString())
                        "
                        @change="changeCheckbox('item')"
                        :value="value.id"
                        class="custom-control-input"
                        :id="`customrangemonth-${value.id}`"
                      />
                      <label
                        class="custom-control-label"
                        :for="`customrangemonth-${value.id}`"
                        >{{ value.text }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="isOther">
                <div class="row">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="formModal.isOther['show-zero']"
                        :checked="formModal.isOther['show-zero']"
                        :value="true"
                        class="custom-control-input"
                        :id="`showzerorange`"
                      />
                      <label class="custom-control-label" :for="`showzerorange`"
                        >Tampilkan data dengan nilai 0</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-save"
                style="width: unset"
                @click="showReport"
              >
                Tampilkan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { v4 as uuid4 } from "uuid";
import { cksClient } from "../../../../helper";
import Datepicker from "vue3-date-time-picker";
import Select2 from "vue3-select2-component";
import moment from "moment";

export default {
  name: "modalRangeMonth",
  props: {
    show: Boolean,
    label: String,
    type: String,
    onPage: Boolean,
    departement: Object,
    parameter: Object,
    isMerge: Boolean,
    isLedger: Boolean,
    akun: Object,
    isMulti: Boolean,
    isOther: Boolean,
    isEnableYear: Boolean,
  },

  components: {
    Datepicker,
    Select2,
  },

  data() {
    return {
      idModal: uuid4(),
      optionDepartement: this.$props.departement,
      optionAkun: this.$props.akun,
      accessDepartement: cksClient().get("_account").akses_departement,
      isMergeValue: this.$props.isMerge ? this.$props.isMerge : false,
      optionYearStart: [],
      optionYearEnd: [],
      isYearEnable: this.$props.isEnableYear ? this.$props.isEnableYear : false,
      formModal: {
        id_divisi:
          this.$props.parameter && this.$props.parameter.id_divisi
            ? this.$props.parameter.id_divisi
            : "",
        date_start:
          this.$props.parameter && this.$props.parameter.date_start
            ? this.$props.parameter.date_start
            : moment().format("YYYY-MM"),
        date_end:
          this.$props.parameter && this.$props.parameter.date_end
            ? this.$props.parameter.date_end
            : moment().format("YYYY-MM"),
        merge:
          this.$props.parameter && this.$props.parameter.merge
            ? this.$props.parameter.merge
            : [],
        isAll:
          this.$props.parameter && this.$props.parameter.isAll
            ? this.$props.parameter.isAll
            : false,
        id_akun:
          this.$props.parameter && this.$props.parameter.id_akun
            ? this.$props.parameter.id_akun
            : "",
        isMonth: !this.$props.isMulti ? true : false,
        isOther:
          this.$props.parameter && this.$props.parameter.isOther
            ? this.$props.parameter.isOther
            : [],
      },
    };
  },

  created() {
    this.setOptionYear();
  },

  methods: {
    setOptionYear() {
      var now = parseInt(moment().format("YYYY"));
      var past = 2010;
      // var future = now;
      for (let i = past; i <= now; i++) {
        // if (i != now) {
          this.optionYearStart.push({
            id: i,
            text: i,
            disabled: false,
          });
        // }
        this.optionYearEnd.push({
          id: i,
          text: i,
          disabled: false,
        });
      }
      // for (let j = now; j <= future; j++) {
      //   this.optionYearStart.push({
      //     id: j,
      //     text: j,
      //     disabled: false,
      //   });
      //   this.optionYearEnd.push({
      //     id: j,
      //     text: j,
      //     disabled: false,
      //   });
      // }
    },
    showModal() {
      // this.formModal.date_start = "";
      // this.date_end = "";
      if (this.accessDepartement && !this.formModal.id_divisi) {
        var data = this.accessDepartement.split("|");
        this.formModal.id_divisi = data[0];
      } else {
        // this.formModal.id_divisi = "";
      }
      $("#" + this.idModal).modal("show");
      $("#" + this.idModal).on("hidden.bs.modal", () => {
        this.$emit("hide", false);
      });
    },
    format(date, format = "MMMM YYYY") {
      if (date && date.year && parseInt(date.month) >= 0) {
        var string = date.year + "-" + (date.month + 1) + "-01";
        return `${moment(string).format(format)}`;
      }
    },
    dateReport(data, key) {
      this.formModal[key] = data;
      if (key == "date_start") {
        var first = moment(
          this.format(this.formModal.date_start, "YYYY-MM")
        ).unix();
        var second = moment(
          this.format(this.formModal.date_end, "YYYY-MM")
        ).unix();
        if (first > second && this.formModal.date_end.length == 2) {
          this.formModal.date_end = "";
        }
      } else if (key == "date_end") {
        var firsta = moment(
          this.format(this.formModal.date_start, "YYYY-MM")
        ).unix();
        var seconda = moment(
          this.format(this.formModal.date_end, "YYYY-MM")
        ).unix();
        if (seconda < firsta && this.formModal.date_start.length == 2) {
          this.formModal.date_start = "";
        }
      }
    },
    setHide() {
      this.showModalRange = false;
    },
    showReport() {
      if (this.formModal.date_start && this.formModal.date_end) {
        $("#" + this.idModal).modal("hide");
        if (!this.$props.onPage) {
          var type = this.$props.type;
          var data = { ...this.formModal };
          if (data.isMonth) {
            data.date_start = this.format(this.formModal.date_start, "YYYY-MM");
            data.date_end = this.format(this.formModal.date_end, "YYYY-MM");
          }
          if (type == "profit_multi") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "MultiPeriodProfitLoss",
              query: data,
            });
          } else if (type == "neraca_multi") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "MultiPeriod",
              query: data,
            });
          } else if (type == "cash_flow") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "CashFlowStandard",
              query: data,
            });
          }
        } else {
          this.$emit("update", this.formModal);
        }
      }
    },
    changeCheckbox(type = "all") {
      if (type != "all") {
        var lengthAll = this.optionDepartement.length;
        var lengthMerge = this.formModal.merge.length;
        if (lengthAll > 0 && lengthAll == lengthMerge) {
          this.formModal.isAll = true;
        } else {
          this.formModal.isAll = false;
        }
      } else {
        this.formModal.merge = [];
        if (this.formModal.isAll) {
          for (const key in this.optionDepartement) {
            if (Object.hasOwnProperty.call(this.optionDepartement, key)) {
              const element = this.optionDepartement[key];
              this.formModal.merge.push(element.id);
            }
          }
        }
      }
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    mySelectEvent(key, val) {
      if (key == "date_start" || key == "date_end") {
        if (key == "date_start") {
          var start = parseInt(this.formModal.date_start);
          var end = parseInt(this.formModal.date_end);
          if (start > end) {
            this.formModal.date_end = "";
          }
          for (const key in this.optionYearEnd) {
            if (Object.hasOwnProperty.call(this.optionYearEnd, key)) {
              const element = this.optionYearEnd[key];
              if (parseInt(val.id) <= parseInt(element.id)) {
                this.optionYearEnd[key].disabled = false;
              } else {
                this.optionYearEnd[key].disabled = true;
              }
            }
          }
        }
      }
    },
  },

  watch: {
    show: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.showModal();
        }, 500);
      }
    },
    departement: function (newVal) {
      this.optionDepartement = newVal;
    },
    akun: function (newVal) {
      this.optionAkun = newVal;
    },
    parameter: function (newVal) {
      this.formModal.id_divisi = newVal.id_divisi ? newVal.id_divisi : "";
      this.formModal.date_start = newVal.date_start ? newVal.date_start : [];
      this.formModal.date_end = newVal.date_end ? newVal.date_end : [];
      this.formModal.merge = newVal.isMerge ? newVal.isMerge : [];
      this.formModal.isOther = newVal.isOther ? newVal.isOther : [];
      this.formModal.id_akun = newVal.id_akun ? newVal.id_akun : "";
      this.formModal.isAll = newVal.isAll ? newVal.isAll : false;
      this.formModal.isMonth = newVal.isMonth ? newVal.isMonth : false;
    },
    isMerge: function (newVal) {
      this.isMergeValue = newVal;
    },
    isEnableYear: function (newVal) {
      this.formModal.isMonth = true;
      this.isYearEnable = newVal;
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.text :nth-child(1) {
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

#modalProfitLoss .modal-dialog {
  max-width: 700px;
}

#modalBalance .modal-dialog {
  max-width: 633px;
}

.text {
  margin-left: 10px;
}

.text :nth-child(2) {
  font-size: 10px;
  color: #898989;
}

.modal-sub-title {
  font-size: 15px;
  font-weight: 600;
}

.form-group .control-label,
.form-group > label {
  font-weight: 500;
}

.checkbox-text {
  font-weight: 500;
  font-size: 13px;
}
</style>
