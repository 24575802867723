<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Laba Rugi Multi Periode</h4>
          </div>

          <!-- <button class="btn-add" @click="generatePdf">Export</button>
          <button
            class="btn-add"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            + Tambah
          </button> -->
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center justify-content-end">
            <button class="btn btn-save" :style="{ minWidth: '170px' }" @click="this.showModalSingleYear = true">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.5082 2.125C12.3311 2.125 12.1469 2.19583 12.0124 2.33042L10.7161 3.62667L13.3724 6.28292L14.6686 4.98667C14.9449 4.71042 14.9449 4.26417 14.6686 3.98792L13.0111 2.33042C12.8694 2.18875 12.6924 2.125 12.5082 2.125ZM9.95819 6.38917L10.6099 7.04083L4.19236 13.4583H3.54069V12.8067L9.95819 6.38917ZM2.12402 12.2188L9.95819 4.38458L12.6144 7.04083L4.78027 14.875H2.12402V12.2188Z"
                  fill="#fff" />
              </svg>
              Ubah Parameter
            </button>
          </div>

          <iframe id="pdfFrame" v-if="!isLoading"></iframe>
          <div v-else :style="{
              height: '450px',
              background: '#8f8f8f',
              position: 'relative',
            }">
            <loading :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }" :active="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage" color="#68caf8"
              height="80" width="80"></loading>
          </div>
        </div>
      </section>
    </div>
  </div>
  <modalRangeMonth :onPage="true" :show="showModalSingleYear" :type="'profit_multi'" :isMerge="true" @hide="setHide()"
    @update="updateData($event)" :departement="optionDepartement" :isMulti="true" :isOther="true"
    :parameter="parameterReport"></modalRangeMonth>
</template>

<script>
  import SidebarDashboard from "../../../../components/Sidebar.vue";
  import NavbarDashboard from "../../../../components/Navbar.vue";
  import modalRangeMonth from "../../components/report/modalRangeMonth.vue";
  // import Select2 from "vue3-select2-component";
  import {
    jsPDF
  } from "jspdf";
  import "jspdf-autotable";
  import "../../../../assets/font/poppins-bold.js";
  import "../../../../assets/font/poppins-regular.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import Loading from "vue3-loading-overlay";

  import $ from "jquery";
  import {
    get_ReportProfitMulti
  } from "../../../../actions/report/profit";
  import {
    cksClient
  } from "../../../../helper";
  import moment from "moment";
  import "moment/locale/id";
  import {
    get_ListDivisi
  } from "../../../../actions/master";
import { DATA } from '../../../../constans/Url';
  // import { NONE } from "apisauce";

  // import TableLite from 'vue3-table-lite'
  export default {
    name: "dataDepartement",
    components: {
      SidebarDashboard,
      NavbarDashboard,
      // Select2,
      Loading,
      modalRangeMonth,
    },
    data() {
      return {
        showModalSingleYear: false,
        fullPage: true,
        isLoading: true,
        thr: "thr",
        bonus: "bonus",
        gaji: "gaji",
        canvas: null,
        optionDepartement: [],
        dataEstimatedAccount: [],
        parameterReport: {
          id_divisi: this.$route.query.id_divisi,
          date_start: this.$route.query.date_start,
          date_end: this.$route.query.date_end,
          merge: this.$route.query.merge,
          isAll: this.$route.query.isAll,
          isMonth: this.$route.query.isMonth,
        },
        dataCompany: {
        nama: "",
        divisi: "",
        alamat: "",
        email: "",
        no_telp: "",
        logo: "",
      },
        dataReport: [],
        dataColumn: [],
        titleDate: "",
        titleDateCol: "",
        dateColumn: {},
        namaReport: "",
      };
    },

    mounted() {
      if (
        this.$route.query.isMonth === true ||
        this.$route.query.isMonth == "true"
      ) {
        var split = this.$route.query.date_start.split("-");
        this.parameterReport.date_start = {
          year: split[0] ? split[0] : "",
          month: split[1] ? parseInt(split[1]) - 1 : "",
        };

        var splitend = this.$route.query.date_end.split("-");
        this.parameterReport.date_end = {
          year: splitend[0] ? splitend[0] : "",
          month: splitend[1] ? parseInt(splitend[1]) - 1 : "",
        };
      }
      if (this.parameterReport.date_start && this.parameterReport.date_end) {
        this.getData();
      }
      // this.tooltip();
      // this.exportPdf();
    },

    created() {
      this.getDivisi();
    },

    methods: {
      format(date, format = "MMMM YYYY") {
        if (date && date.year && date.month >= 0) {
          var string = date.year + "-" + (date.month + 1) + "-01";
          return `${moment(string).format(format)}`;
        }
      },
      getData() {
        var data = {
          ...this.parameterReport
        };
        data.id_company = cksClient().get("_account").id_company;
        if (data.isMonth === true || data.isMonth == "true") {
          data.date_start = this.format(data.date_start, "YYYY-MM");
          data.date_end = this.format(data.date_end, "YYYY-MM");
        }
        this.isLoading = true;
        get_ReportProfitMulti(
          data,
          async (res) => {
              const {
                pendapatan,
                pendapatan_lainnya,
                beban_pokok_penjualan,
                beban_operasional,
                beban_lainnya,
                pajak_penghasilan,
                dateArr,
                divisi,
                company
              } = res;
              this.dataCompany = company;
          this.dataCompany.divisi = divisi;
              if (divisi) {
                this.namaReport = divisi;
              } else {
                this.namaReport = cksClient().get("_account").nama_company;
              }
              this.dataColumn = [{
                title: "Deskripsi",
                dataKey: "akun",
              }, ];
              for (const key in dateArr) {
                if (Object.hasOwnProperty.call(dateArr, key)) {
                  const element = dateArr[key];
                  this.dataColumn.push({
                    title: this.parameterReport.isMonth == "true" ||
                      this.parameterReport.isMonth == true ?
                      moment(element + "-01").format("MMMM YYYY") : element,
                    dataKey: element,
                  });
                  this.dateColumn[element] = 0;
                }
              }
              this.dataColumn.push({
                title: "Total",
                dataKey: "nilai",
              });
              this.dataReport = [];
              var data = [];
              data.push({
                akun: "PENDAPATAN",
                padding: 0,
                nilai: "",
                bold: true,
              });
              var jumlah_pendapatan = 0;
              for (const key in pendapatan) {
                if (Object.hasOwnProperty.call(pendapatan, key)) {
                  var element = pendapatan[key];
                  element.padding = 1 * element.sub_level;
                  var nilai = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilai += element[el] ? parseInt(element[el]) : 0;
                      element[el] = element[el] ? this.formatMoney(element[el]) : 0;
                    }
                  }
                  element.nilai = this.formatMoney(nilai);
                  if (element.sub_level === 1 || element.sub_level === "1") {
                    jumlah_pendapatan += element.nilai ?
                      parseInt(element.nilai.toString().split(".").join("")) :
                      0;
                  }
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (element.sub_level == "1" || element.sub_level == 1)
                  ) {
                    element.color = "#FF3D3D";
                  }
                  data.push(element);
                }
              }
              var arryJmlPendapatan = await this.countJumlahPendapatan(pendapatan);
              data.push({
                akun: "Jumlah Pendapatan",
                padding: 0,
                nilai: this.formatMoney(parseInt(jumlah_pendapatan)),
                ...arryJmlPendapatan,
                bold: true,
              });

              data.push({
                akun: "",
                padding: 0,
                nilai: "",
                bold: true,
              }, {
                akun: "BEBAN USAHA",
                padding: 0,
                nilai: "",
                bold: true,
              });
              var jumlah_beban_pokok = 0;
              for (const key in beban_pokok_penjualan) {
                if (Object.hasOwnProperty.call(beban_pokok_penjualan, key)) {
                  var elbeban = beban_pokok_penjualan[key];
                  elbeban.padding = 1 * elbeban.sub_level;
                  var nilaibeban = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaibeban += elbeban[el] ? parseInt(elbeban[el]) : 0;
                      elbeban[el] = elbeban[el] ? this.formatMoney(elbeban[el]) : 0;
                    }
                  }
                  if (elbeban.sub_level === 1 || elbeban.sub_level === "1") {
                    jumlah_beban_pokok += nilaibeban ?
                      parseInt(nilaibeban.toString().split(".").join("")) :
                      0;
                  }
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elbeban.sub_level == "1" || elbeban.sub_level == 1)
                  ) {
                    elbeban.color = "#FF3D3D";
                  }
                  elbeban.nilai = this.formatMoney(nilaibeban);
                  elbeban.nilai = elbeban.nilai ?
                    this.formatMoney(elbeban.nilai) :
                    0;
                  data.push(elbeban);
                }
              }

              var arryJmlPokokPenjualan = await this.countJumlahPendapatan(
                beban_pokok_penjualan
              );

              var objLaba = await this.countSummaryProfit("laba kotor", {
                pendapatan: arryJmlPendapatan,
                pokok_penjualan: arryJmlPokokPenjualan,
              });

              data.push({
                akun: "Jumlah Beban Usaha",
                padding: 0,
                nilai: this.formatMoney(parseInt(jumlah_beban_pokok)),
                ...arryJmlPokokPenjualan,
                bold: true,
              }, {
                akun: "",
                padding: "",
                nilai: "",
                bold: true,
              }, {
                akun: "LABA KOTOR",
                padding: 0,
                nilai: this.formatMoney(
                  parseInt(jumlah_pendapatan) - parseInt(jumlah_beban_pokok)
                ),
                ...objLaba,
                bold: true,
              }, {
                akun: "",
                padding: "",
                nilai: "",
                bold: true,
              }, {
                nama: "Beban Operasional",
                padding: 0,
                nilai: "",
                bold: true,
              });

              var jumlah_beban_operasional = 0;
              for (const key in beban_operasional) {
                if (Object.hasOwnProperty.call(beban_operasional, key)) {
                  var elbebanops = beban_operasional[key];
                  elbebanops.padding = 1 * elbebanops.sub_level;
                  var nilaiops = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaiops += elbebanops[el] ? parseInt(elbebanops[el]) : 0;
                      elbebanops[el] = elbebanops[el] ?
                        this.formatMoney(elbebanops[el]) :
                        0;
                    }
                  }
                  if (elbebanops.sub_level === 1 || elbebanops.sub_level === "1") {
                    jumlah_beban_operasional += nilaiops;
                  }
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elbebanops.sub_level == "1" || elbebanops.sub_level == 1)
                  ) {
                    elbebanops.color = "#FF3D3D";
                  }
                  elbebanops.nilai = this.formatMoney(nilaiops);
                  elbebanops.nilai = elbebanops.nilai ?
                    this.formatMoney(elbebanops.nilai) :
                    0;
                  data.push(elbebanops);
                }
              }

              var arryJmlBebanOperssional = await this.countJumlahPendapatan(
                beban_operasional
              );

              var objPendapatanOps = await this.countSummaryProfit(
                "pendapatan_ops", {
                  pendapatan: arryJmlPendapatan,
                  pokok_penjualan: arryJmlPokokPenjualan,
                  pokok_ops: arryJmlBebanOperssional,
                }
              );

              data.push({
                akun: "Jumlah Beban Operasional",
                padding: 0,
                nilai: this.formatMoney(jumlah_beban_operasional),
                ...arryJmlBebanOperssional,
                bold: true,
              }, {
                akun: "",
                padding: "",
                nilai: "",
                bold: true,
              }, {
                akun: "PENDAPATAN OPERASIONAL",
                padding: 0,
                nilai: this.formatMoney(
                  parseInt(jumlah_pendapatan) -
                  parseInt(jumlah_beban_pokok) -
                  parseInt(jumlah_beban_operasional)
                ),
                ...objPendapatanOps,
                bold: true,
              }, {
                akun: "",
                padding: "",
                nilai: "",
                bold: true,
              }, {
                akun: "PENDAPATAN DAN BEBAN NON OPERASIONAL",
                padding: 0,
                nilai: "",
                bold: true,
              }, {
                akun: "Pendapatan Non Operasional",
                padding: 1,
                nilai: "",
                bold: true,
              });

              var jumlah_pendapatan_lainnya = 0;
              for (const key in pendapatan_lainnya) {
                if (Object.hasOwnProperty.call(pendapatan_lainnya, key)) {
                  var elpen = pendapatan_lainnya[key];
                  elpen.padding = 2 * elpen.sub_level;
                  var nilaipen = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaipen += elpen[el] ? parseInt(elpen[el]) : 0;
                      elpen[el] = elpen[el] ? this.formatMoney(elpen[el]) : 0;
                    }
                  }
                  if (elpen.sub_level === 1 || elpen.sub_level === "1") {
                    jumlah_pendapatan_lainnya += nilaipen ?
                      parseInt(nilaipen.toString().split(".").join("")) :
                      0;
                  }
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elpen.sub_level == "1" || elpen.sub_level == 1)
                  ) {
                    elpen.color = "#FF3D3D";
                  }
                  elpen.nilai = this.formatMoney(nilaipen);
                  elpen.nilai = elpen.nilai ? this.formatMoney(elpen.nilai) : 0;
                  data.push(elpen);
                }
              }

              var arryJmlPendapatanLainnya = await this.countJumlahPendapatan(
                pendapatan_lainnya
              );

              data.push({
                akun: "Jumlah Pendapatan Non Operasional",
                padding: 1,
                nilai: this.formatMoney(jumlah_pendapatan_lainnya),
                ...arryJmlPendapatanLainnya,
                bold: true,
              }, {
                akun: "Beban Non Operasional",
                padding: 1,
                nilai: "",
                bold: true,
              });

              var jumlah_beban_lainnya = 0;
              for (const key in beban_lainnya) {
                if (Object.hasOwnProperty.call(beban_lainnya, key)) {
                  var elbebanl = beban_lainnya[key];

                  elbebanl.padding = 2 * elbebanl.sub_level;
                  var nilaibebanl = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaibebanl += elbebanl[el] ? parseInt(elbebanl[el]) : 0;
                      elbebanl[el] = elbebanl[el] ?
                        this.formatMoney(elbebanl[el]) :
                        0;
                    }
                  }
                  if (elbebanl.sub_level === 1 || elbebanl.sub_level === "1") {
                    jumlah_beban_lainnya += nilaibebanl ?
                      parseInt(nilaibebanl.toString().split(".").join("")) :
                      0;
                  }
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elbebanl.sub_level == "1" || elbebanl.sub_level == 1)
                  ) {
                    elbebanl.color = "#FF3D3D";
                  }
                  elbebanl.nilai = this.formatMoney(nilaibebanl);
                  elbebanl.nilai = elbebanl.nilai ?
                    this.formatMoney(elbebanl.nilai) :
                    0;
                  data.push(elbebanl);
                }
              }

              var arryJmlBebanLainnya = await this.countJumlahPendapatan(
                beban_lainnya
              );

              var objNonops = await this.countSummaryProfit("non_ops", {
                pendapatan: arryJmlPendapatanLainnya,
                pokok_penjualan: arryJmlBebanLainnya,
              });

              var arryJmlPajakPenghasilan = await this.countJumlahPendapatan(
                pajak_penghasilan
              );

              var objLabaAll = await this.countSummaryProfit("laba", {
                pendapatan: arryJmlPendapatan,
                pokok_penjualan: arryJmlPokokPenjualan,
                pokok_ops: arryJmlBebanOperssional,
                pendapatan_lain: arryJmlPendapatanLainnya,
                beban_lain: arryJmlBebanLainnya,
              });

              var objLabaPajakAll = await this.countSummaryProfit("laba_pajak", {
                pendapatan: arryJmlPendapatan,
                pokok_penjualan: arryJmlPokokPenjualan,
                pokok_ops: arryJmlBebanOperssional,
                pendapatan_lain: arryJmlPendapatanLainnya,
                beban_lain: arryJmlBebanLainnya,
                pajak: arryJmlPajakPenghasilan,
              });

              var jumlah_pajak_penghasilan = 0;
              for (const key in pajak_penghasilan) {
                if (Object.hasOwnProperty.call(pajak_penghasilan, key)) {
                  var elPajak = pajak_penghasilan[key];
                  var nilaiPajak = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaiPajak += elPajak[el] ? parseInt(elPajak[el]) : 0;
                      elPajak[el] = elPajak[el] ? this.formatMoney(elPajak[el]) : 0;
                    }
                  }
                  if (elPajak.sub_level === 1 || elPajak.sub_level === "1") {
                    jumlah_pajak_penghasilan += nilaiPajak ?
                      parseInt(nilaiPajak.toString().split(".").join("")) :
                      0;
                  }
                }
              }
              // var objLabaAll = {};
              // console.log()

              var laba =
                parseInt(jumlah_pendapatan) -
                parseInt(jumlah_beban_pokok) -
                parseInt(jumlah_beban_operasional) +
                parseInt(jumlah_pendapatan_lainnya) -
                parseInt(jumlah_beban_lainnya);
              data.push({
                  akun: "Jumlah Beban Non Operasional",
                  padding: 1,
                  nilai: this.formatMoney(jumlah_beban_lainnya),
                  ...arryJmlBebanLainnya,
                  bold: true,
                }, {
                  akun: "Jumlah Pendapatan dan Beban Non Operasional",
                  padding: 0,
                  nilai: this.formatMoney(
                    jumlah_pendapatan_lainnya - jumlah_beban_lainnya
                  ),
                  ...objNonops,
                  bold: true,
                }, {
                  akun: "",
                  padding: 0,
                  nilai: "",
                }, {
                  akun: "LABA BERSIH (Sebelum Pajak)",
                  padding: 0,
                  nilai: this.formatMoney(laba),
                  ...objLabaAll,
                  bold: true,
                }, {
                  akun: "Jumlah Pajak Penghasilan",
                  padding: 0,
                  // bold:
                  nilai: this.formatMoney(jumlah_pajak_penghasilan),
                  ...arryJmlPajakPenghasilan,
                },

                {
                  akun: "LABA BERSIH (Setelah Pajak)",
                  padding: 0,
                  nilai: this.formatMoney(laba - jumlah_pajak_penghasilan),
                  ...objLabaPajakAll,
                  bold: true,
                }
              );

              this.dataReport = data;
              this.convertDate(dateArr[0], dateArr[dateArr.length - 1]);
              // this.convertDate(year);
              this.isLoading = false;
              this.generatePdf();
            },
            (err) => {
              console.log(err);
            }
        );
      },

      moneyToInt(string) {
        return string ? parseInt(string.toString().split(".").join("")) : 0;
      },

      summaryArray(obj, type = "") {
        var nilai = 0;
        var i = 0;
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            if (type == "beban") {
              if (i == 0) {
                nilai = parseInt(element.toString().split(".").join(""));
              } else if (
                parseInt(element.toString().split(".").join("")) >= 0 &&
                nilai != 0
              ) {
                nilai -= parseInt(element.toString().split(".").join(""));
              } else {
                nilai += parseInt(element.toString().split(".").join(""));
              }
            } else {
              if (i == 0) {
                nilai = parseInt(element.toString().split(".").join(""));
              } else if (
                parseInt(element.toString().split(".").join("")) >= 0 &&
                nilai != 0
              ) {
                nilai -= parseInt(element.toString().split(".").join(""));
              } else {
                nilai += parseInt(element.toString().split(".").join(""));
              }
            }
            i++;
          }
        }
        return nilai;
      },

      countJumlahPendapatan(data) {
        var object = {
          ...this.dateColumn
        };

        for (const i in data) {
          const element = data[i];
          for (const key in element) {
            if (
              Object.hasOwnProperty.call(element, key) &&
              key != "nilai" &&
              key != "akun" &&
              key != "padding"
            ) {
              const el = element[key];
              if (element.sub_level === 1 || element.sub_level === "1") {
                object[key] += el ?
                  parseInt(el.toString().split(".").join("")) :
                  0;
              }
            }
          }
        }

        for (const key in object) {
          if (Object.hasOwnProperty.call(object, key)) {
            object[key] = object[key] ? this.formatMoney(object[key]) : 0;
          }
        }

        return object;
      },

      countSummaryProfit(tipe = "", obj) {
        var object = {
          ...this.dateColumn
        };
        if (tipe == "laba kotor" || tipe == "non_ops") {
          for (const i in object) {
            if (Object.hasOwnProperty.call(object, i)) {
              object[i] = this.formatMoney(
                parseInt(obj.pendapatan[i].toString().split(".").join("")) -
                parseInt(obj.pokok_penjualan[i].toString().split(".").join(""))
              );
            }
          }
        } else if (tipe == "pendapatan_ops") {
          for (const i in object) {
            if (Object.hasOwnProperty.call(object, i)) {
              object[i] = this.formatMoney(
                this.summaryArray({
                  pendapatan: obj.pendapatan[i],
                  beban_pokok: obj.pokok_penjualan[i],
                  beban_operasional: obj.pokok_ops[i],
                })
              );
            }
          }
        } else if (tipe == "laba" || tipe == "laba_pajak") {
          for (const i in object) {
            if (Object.hasOwnProperty.call(object, i)) {
              var pajak =
                tipe == "laba_pajak" ? this.moneyToInt(obj.pajak[i]) : 0;
              object[i] = this.formatMoney(
                this.summaryArray({
                  pendapatan: obj.pendapatan[i],
                  beban_pokok: obj.pokok_penjualan[i],
                  beban_operasional: obj.pokok_ops[i],
                }) +
                this.moneyToInt(obj.pendapatan_lain[i]) -
                this.moneyToInt(obj.beban_lain[i]) -
                pajak
              );
            }
          }
        }
        return object;
      },

      setHide() {
        this.showModalSingleYear = false;
      },

      updateData(event) {
        this.parameterReport = event;
        this.getData();
      },

      getDivisi() {
        get_ListDivisi({
            id_company: cksClient().get("_account").id_company,
          },
          (res) => {
            this.optionDepartement = res.list;
          }
        );
      },
      convertDate(start, end) {
        if (
          this.parameterReport.isMonth == true ||
          this.parameterReport.isMonth == "true"
        ) {
          var startMonth = moment(start + "-01");
          var endMonth = moment(end + "-01");
          if (startMonth.format("YYYY") === endMonth.format("YYYY")) {
            this.titleDate = `Per ${startMonth.format(
            "MMMM"
          )} s/d ${endMonth.format("MMMM YYYY")}`;
          } else {
            this.titleDate = `Per ${startMonth.format(
            "MMMM YYYY"
          )} s/d ${endMonth.format("MMMM YYYY")}`;
          }
        } else {
          this.titleDate = `Per ${start} s/d ${end}`;
        }
      },
      formatMoney(a) {
        if (!a) {
          return 0;
        }
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#6BACCC"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoTwoArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 2);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      // const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const secondArray = wordsArray.slice(arraySize);

      return [firstArray, secondArray];
    },

      async generatePdf() {
        var tableData = {
          columns: this.dataColumn,
          rows: this.dataReport,
        };

        let height = 210;
        let width = 297;
        if (tableData.columns.length > 4) {
          let widthColumn = tableData.columns.length * 50 + 100;
          width = widthColumn;

        }
        const doc = new jsPDF("l", "mm", [height, width]);
        var header = "img/bg-header.png";
        doc.addImage(header, "png", width, -35, width, 2500 / 72, null, null, 180);
        var logo = await this.convertImgToBase64(
        DATA.COMPANY(this.dataCompany.logo)
      );
        doc.addImage(logo, "png", 13, 6, 23, 23, null, null, 0);

        doc.setFont("Poppins-Bold").setFontSize(15);
        doc.setTextColor(255, 255, 255);
        doc.text(40, 15, `${this.dataCompany.nama}`, {
          align: "left",
        });
        doc.setFont("Poppins-Regular").setFontSize(10);
        doc.setTextColor(255, 255, 255);
        doc.text(40, 22, `${this.dataCompany.divisi ? this.dataCompany.divisi :  this.dataCompany.email}`, {
          align: "left",
        });

        doc.setFontSize(10);
        doc.setFont("Poppins-Regular");
        doc.splitTextToSize(
          this.dataCompany.alamat, 10)

          const [array1, array2] = this.splitTextIntoTwoArrays(this.dataCompany.alamat, 2)
        doc.text(width - 12, 10, array1.join(" "), {
          align: "right",
        });
        doc.text(width - 12, 16, array2.join(" "), {
          align: "right",
        });
        doc.text(width - 12, 22, this.dataCompany.no_telp, {
          align: "right",
        });
        doc.text(width - 12, 28, this.dataCompany.divisi ? this.dataCompany.email : '', {
          align: "right",
        });
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        // doc.text(
        //   this.namaReport,
        //   doc.internal.pageSize.getWidth() / 2,
        //   45,
        //   "center"
        // );
        doc.setFontSize(16);
        doc.text(
          `Laba Rugi (Multi Periode)`,
          doc.internal.pageSize.getWidth() / 2,
          45, {
            align: "center",
          }
        );
        doc.setProperties({
          title: `Laba Rugi (Multi Periode)`,
        });
        doc.setFontSize(10);
        doc.text(this.titleDate, doc.internal.pageSize.getWidth() / 2, 53, {
          align: "center",
        });

        // doc.text(145, 27, "Per Tgl. xx Feb 20xx", "center");
        var blankTable = {
          columns: [{
            title: "",
            dataKey: "id",
          }, ],
          rows: [{
            id: ""
          }, ],
        };

        doc.autoTable(blankTable.columns, blankTable.rows, {
          margin: {
            top: 45,
            bottom: 80,
          },
          theme: "plain",
        });
        doc.autoTable(tableData.columns, tableData.rows, {
          showHead: "everyPage",

          didParseCell: function (data) {
            if (data.column.index === 0) {
              var padding = data.row.raw.padding ?
                parseInt(data.row.raw.padding) :
                1;
              data.cell.styles.cellPadding = {
                left: 3 * padding
              };
            }

            if (data.row.raw.bold && data.column.index === 0) {
              data.cell.styles.fontStyle = "bold";
            }

            if (data.row.section === "head") {
              // data.cell.styles.halign = "center";
              data.cell.styles.textColor = "#324C8F";
              data.cell.styles.halign = "left";
            }
            if (data.column.dataKey !== "akun") {
              data.cell.styles.halign = "right";
            }

            if (data.row.raw.color) {
              data.cell.styles.textColor = data.row.raw.color;
            }
          },

          columnStyles: {
            0: {
              cellPadding: 1,
              cellWidth: 100
            },
            1: {
              halign: "right"
            },
            2: {
              halign: "right"
            },
            3: {
              halign: "right"
            },
          },
          margin: {
            top: 10,
            bottom: 60
          },
          theme: "plain",
        });

        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = doc.internal.getNumberOfPages();
        // var width = doc.internal.pageSize.getWidth();
        // var height = doc.internal.pageSize.getHeight();

        // For each page, print the page number and the total pages
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);

          //Print Page 1 of 4 for example
          // var widthPage = doc.internal.pageSize.getWidth();
          // var heightPage = doc.internal.pageSize.getHeight();
          var img = new Image();
          img.src = "img/line.png";
          doc.addImage(
            img,
            "png",
            width - 20,
            165,
            width - 40,
            1 / 72,
            null,
            null,
            180
          );

          doc.text(
            `Finanta Accounting System Report`,
            doc.internal.pageSize.getWidth() / 2,
            180, {
              align: "center",
            }
          );
          // doc.text(
          //   "Page " + String(i) + " of " + String(pageCount),
          //   90 - 20,
          //   210 - 30,
          //   null,
          //   null,
          //   "right"
          // );

          if (tableData.columns.length > 4) {
            doc.text(
              "Page " + String(i) + " of " + String(pageCount),
              doc.internal.pageSize.width / 1.09,
              180, {
                align: "center",
              }
            );
          } else {
            doc.text(
              "Page " + String(i) + " of " + String(pageCount),
              300 - 20,
              210 - 30,
              null,
              null,
              "right"
            );
          }

          doc.text(
            `Tercetak pada ${moment().format("DD MMMM YYYY - HH:mm")}`,
            205 - 192,
            210 - 30,
            null,
            null,
            "left"
          );
        }

        setTimeout(() => {
          var iframe = document.getElementById("pdfFrame");
          iframe.src = doc.output("bloburi", {
            filename: `Laba Rugi ${this.namaReport} (Multi Periode).pdf`,
          });
        }, 500);
      },

      tooltip() {
        $('[data-toggle="tooltip"]').tooltip();
      },

      detail(payroll) {
        const getPayroll = payroll;

        if (getPayroll == "bonus") {
          this.$router.push("/data-payroll/detail-bonus");
        }
        if (getPayroll == "gaji") {
          this.$router.push("/data-payroll/detail-gaji");
        }
        if (getPayroll == "thr") {
          this.$router.push("/data-payroll/detail-thr");
        }

        $('[data-toggle="tooltip"]').tooltip("hide");
      },
    },
  };
</script>

<style scoped>
  .card-table {
    margin-top: 0px !important;
  }

  .btn-gaji {
    background-color: #68caf8;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .btn-bonus {
    background-color: #f8ce3a;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .btn-thr {
    background-color: #ff5252;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .dropdown-menu {
    background: transparent !important;
    box-shadow: none !important;
    width: 102px !important;
    min-width: unset;
  }

  .dropdown-item:nth-child(2) {
    margin-top: 6px;
  }

  .dropdown-item:nth-child(3) {
    margin-top: 6px;
  }

  iframe {
    z-index: 1000;
    width: 100%;
    height: 800px;
  }
</style>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>